import AOS from 'aos';

jQuery( document ).ready(function($) {
  // AOS.init({
  //   once: true,
  //   duration: 1000,
  //   disable: 'mobile',
  // });
  // let scrollRef = 0
  // window.addEventListener('scroll', () => 0 <= 10 ? scrollRef++ : AOS.refresh() );
});